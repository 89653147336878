.input-with-button {
    width: 100%;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
}

.input-with-button__input {
    padding: 0;
    margin: 0;
    height: 40px;
    width: 70%;
    margin: 0;
    padding: 0;
    border: 1px solid #333b426b; /* Цвет рамки */
    border-radius: 10px 0 0 10px;
    box-shadow: none; /* Убрать тень */
}

.input-with-button__button {
    /* color: #27bae3; */
    color: #ffffff;
    font-size: 14px;
    font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial;
    font-weight: 500;
    height: 42px;
    width: 30%;
    margin: 0;
    padding: 0;
    background-color: #333b42;
    /* background-color: #0056d3; */
    border: 1px solid #333b42; /* Цвет рамки */
    border-radius: 0 10px 10px 0;
}