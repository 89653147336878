.main-page {
    margin: 70px 70px;
}

@media (max-width: 650px) {
    .main-page {
        margin: 40px;
    }
}

.main-page__title {
    text-align: left;
    font-size: 29px;
    font-weight: 500;
    margin: 0;
}

.main-page__subtitle {
    text-align: left;
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    margin: 10px 0;
}

.main-page__img {
    width: 100%;
    max-width: 900px;
    border-radius: 50px;
}

.main-page__container {
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    margin: 70px 0;
}

.main-page__container-revers {
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    margin: 70px 0;
}

.main-page__container-item {
    display: flex;
    min-height: auto;
    flex-wrap: wrap;
    align-content: center;
    padding: 0 30px;
}

@media (max-width: 450px) {
    .main-page__container-item {
        padding: 11px 10px;
    }
}

@media (max-width: 450px) {
    .main-page__container {
        flex-direction: column;
        margin: 20px 0;
    }

    .main-page__container-revers {
        flex-direction: column-reverse;
        margin: 20px 0;
    }

    .main-page__btn {
        width: 100% !important;
    }
}

.main-page__btn {
    width: 250px;
}

@media (max-width: 650px) {
    .main-page__btn {
        width: 170px;
    }
}

.main-page__test {
    display: flex;
    flex-direction: column;
    padding: 30px 0;
    justify-content: center;
    align-items: center;
}

.main-page__title-test {
    text-align: center;
    font-size: 29px;
    font-weight: 500;
    margin: 0;
}

.main-page__subtitle-test {
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    margin: 10px 0;
}

@media (max-width: 598px) {
    .main-page__title {
        font-size: 20px;

    }

    .main-page__subtitle {
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
    }

    .main-page__title-test {
        font-size: 20px;
    }

    .main-page__subtitle-test {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
    }
}

.main-page__btn-test {
    width: 270px;
    padding-top: 20px;
}