.privacy-page {
    background-color: rgb(255, 255, 255);
    /* width: 100vw; */
    min-height: 100vh;
    padding: 50px;
    margin: 0;
}

.privacy-page-en {
    padding: 10px;
}

.privacy-page_title {
    font-size: 24px;
    text-align: center;
    color: rgb(59, 59, 59);
    margin: 0 10px 10px;
    padding-top: 30px;
}

@media (max-width: 768px) {
    .privacy-page_title {
        font-size: 17px;
        font-weight: bold;
    }
}

.privacy-page_text {
    max-width: 100%;
    color: rgb(54, 54, 54);
    word-wrap: break-word;
    font-size: 14px;
    margin: 10px 10px 0 25px;
}

@media (max-width: 768px) {
    .privacy-page_text {
        font-size: 12px;
    }
}

.privacy-page_text-view {
    max-width: 100%;
    color: rgb(65, 65, 65);
    word-wrap: break-word;
    font-size: 14px;
    margin: 5px 10px 0;
}

@media (max-width: 768px) {
    .privacy-page_text-view {
        font-size: 12px;
    }
}

.privacy-page_subtitle {
    font-size: 18px;
    font-weight: bold;
    color: rgb(63, 63, 63);
    margin: 30px 10px 0;
}

@media (max-width: 768px) {
    .privacy-page_subtitle {
        font-size: 14px;
    }
}