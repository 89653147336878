.button {
    /* color: #27bae3; */
    color: white;
    font-size: 17px;
    font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial;
    font-weight: 500;
    margin: 0;
    padding: 10px;
    /* background-color: #333b42; */
    background-color: #3a9efe;
    border: none; /* Цвет рамки */
    border-radius: 10px;
    width: 100%;
    transition: background-color 0.7s;
}

.button:hover {
    cursor: pointer;
    background-color: #3a9efe;
}


@media (max-width: 650px) {
    .button {
        font-size: 14px;
    }
}