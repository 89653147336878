.search-form {
    max-width: 610px;
    margin: 0 auto 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


@media screen and (max-width: 380px) {
    .search-form {
        width: calc(100vw - 20px);
        flex-direction: column;
    }
}

.search-form__border__border-show {
    border-right: 1px solid #2b48bd;
    padding-right: 30px;
}

@media screen and (max-width: 769px) {
    .search-form__border__border-show {
        padding-right: 19px;
    }
}

@media screen and (max-width: 582px) {
    .search-form__border__border-show {
        border-right: none;
    }
}

.search-form__button_off {
    background-color: #EBEBEB;
    background-position: top -2px left -4px;
    transition: background-position 1s, background-color 1s;
}

.search-form__input {
    background-color: #002efb53;
    border: none;
    color: #A0A0A0;
    font-family: 'Inter', Arial, sans-serif;;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    width: 100%;
    height: 100%;
    padding: 15px;
}

@media screen and (max-width: 582px) {
    .search-form__input {
        border-radius: 9px 0 0 9px;
        padding-left: 19px;
    }
}

.search-form__input-button {
    background-color: transparent;
    border: none;
    height: 100%;
    transition: opacity 1s;
    margin: 0;
    padding: 0;
}

.search-form__input-button:hover {
    opacity: 0.5;
    cursor: pointer;
}

@media screen and (max-width: 582px) {
    .search-form__input-button {
        border-radius: 0 9px 9px 0;
    }
}


.search-form__img {
    width: 34px;
    padding: 5px 0 0;
    /* border-right: 1px solid #E8E8E8;
    padding-right: 30px; */
}

@media screen and (max-width: 769px) {
    .search-form__img {
        padding-right: 19px;
    }
}

@media screen and (max-width: 582px) {
    .search-form__img {
        border-right: none;
    }
}

.search-form__icon {
    color: #A0A0A0;
    width: 15px;
    background-color: #F9F9F9;
    border-radius: 9px 0 0 9px;
    height: 100%;
    padding: 0 10px 0 19px;
    background: url(../images/icon-movies.svg) #F9F9F9 50% no-repeat;
}

@media screen and (max-width: 430px) {
    .search-form__icon {
        background-image: none;
        width: 0;
        padding: 0;
    }
}

.search-form__container {
    background-color: #f9f9f9;
    border-radius: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    min-height: 32px;
    width: 100%;
    margin: 0;
}

@media screen and (max-width: 580px) {
    .search-form__container {
        width: 100%;
        flex-direction: column;
        border-radius: 9px 0 0 9px;
    }
}

.search-form__container-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.search-form__checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #F9F9F9;
    /* height: 72px; */
    border-radius: 0 9px 9px 0;
    padding: 0 30px 0 29px;
}

@media screen and (max-width: 580px) {
    .search-form__checkbox {
        padding: 0 20px 0 19px;
        margin: 46px 0 0;
        height: max-content;
        background-color: white;
    }
}

.search-form__button {
    width: 36px;
    height: 20px;
    border-radius: 20px;
    border: none;
    background-repeat: no-repeat;
    cursor: pointer;
    background-image: url(../images/tumb__COLOR_tumbler-on-2.svg);
    transition: background-position 1s, background-color 1s;
}

.search-form__button-name {
    font-family: 'Inter', Arial, sans-serif;;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0 0 0 14px;
    margin: 0;
}

@media screen and (max-width: 769px) {
    .search-form__button-name {
        padding: 0 0 0 15px;
    }
}

.search-form__button_on {
    background-color: #2BE080;
    background-position: top -2px right -4px;
}
