@import url(./assets/fonts/inter.css);

body {
  margin: 0;
  font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial;
  color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url("./Pages/Main/main-page.css");
@import url("./componentsShared/SearchForm/style/search-form.css");
@import url("./componentsShared/inputs/InputWithButton/style.css");
@import url("./componentsShared/buttons/Button/style.css");
@import url("../src//Pages/PrivacyPage/style/styles.css");
@import url("../src//Pages/NotFoundPage/style/not-found-page.css");
@import url("../src//Pages/RemoveProfile/style/remove-profile.css");